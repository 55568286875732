import React from "react";
import { Link } from "react-router-dom";

import bg from "../../assets/images/rsvp/rsvp.jpg";

const PageTitle = (props) => {
  return (
    <section className="wpo-page-title" style={{ backgroundImage: `url(${bg})` }}>
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="wpo-breadcumb-wrap">
              <h2>{props.pageTitle}</h2>
              {/* <ol className="wpo-breadcumb-wrap">
                <li>
                  <Link to="/home">Home</Link>
                </li>
                <li>
                  <span>{props.pagesub}</span>
                </li>
              </ol> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;
