import React, { Fragment, useState } from "react";
import { QrReader } from "react-qr-reader";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Form, FormFeedback, Input } from "reactstrap";
import Bg from "../../assets/images/invitation/qrbg.jpg";
import frame1 from "../../assets/images/slider/shape13.svg";
import frame2 from "../../assets/images/slider/shape14.svg";

import shape1 from "../../assets/images/slider/corner1.svg";
import shape2 from "../../assets/images/slider/corner2.svg";
import shape3 from "../../assets/images/slider/corner3.svg";
import shape4 from "../../assets/images/slider/corner4.svg";
import { verifyQRCode } from "../../utils/api";

const QrVerification = () => {
  const [cipher, setCipher] = useState("");
  const [guest, setGuest] = useState("");
  const [error, setError] = useState("");
  const resetState = () => {
    setError("");
    setGuest("");
    setCipher("");
  };

  const verifyQR = async (data) => {
    const response = await verifyQRCode(data);
    if (response) {
      if (response.statusCode === 0) {
        setGuest(response.data.name);
      } else {
        setError(response.statusMessage);
      }
      if (response.statusCode === 0) {
        validation.resetForm();
      }
    } else {
      setError("Unable to complete request. Try again");
    }
    setTimeout(resetState, 5000);
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      key: "",
    },
    validationSchema: Yup.object({
      key: Yup.string().required("Enter verification key"),
    }),
    onSubmit: (values) => {
      const payload = {
        cipher: cipher,
        key: values.key,
      };
      console.log(JSON.stringify(payload));
      verifyQR(payload);
    },
  });

  return (
    <Fragment>
      <section
        className="wpo-coming-soon-area"
        style={{ background: `url(${Bg}) no-repeat center top / cover` }}
      >
        <div className="hero-container">
          <div className="hero-inner">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="wpo-event-wrap">
                    <div className="wpo-event-item">
                      <div className="coming-soon-section">
                        <div className="row justify-content-center">
                          <div className="col-lg-8">
                            <div className="coming-soon-text">
                              <h2>Guest Reservation Verification</h2>
                              {error !== "" ? (
                                <h4 className="text-danger">{error}</h4>
                              ) : null}
                              {guest !== "" ? (
                                <h2 className="text-success text-center">{`Welcome ${guest}`}</h2>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="wpo-coming-contact">
                              <Form
                                method="post"
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  validation.handleSubmit();
                                  return false;
                                }}
                                className="contact-validation-active"
                                id="contact-form-main"
                              >
                                <div className="justify-content-center">
                                  {cipher === "" ? (
                                    <div className="row">
                                      <QrReader
                                        constraints={{
                                          facingMode: "environment",
                                        }}
                                        onResult={(result, error) => {
                                          if (!!result) {
                                            setCipher(result?.text);
                                          }

                                          if (!!error) {
                                            console.info(error);
                                          }
                                        }}
                                        style={{ width: "50%" }}
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      <div className="row">
                                        <div className="col-lg-8 col-sm-12 col-12">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            name="key"
                                            id="key"
                                            placeholder="Enter Verification Key"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.key || ""}
                                            invalid={
                                              validation.touched.key &&
                                              validation.errors.key
                                                ? true
                                                : false
                                            }
                                          />
                                          {validation.touched.key &&
                                          validation.errors.key ? (
                                            <FormFeedback type="invalid">
                                              {validation.errors.key}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-4 col-sm-4 col-6">
                                          <div className="submit-area">
                                            <Button type="submit">
                                              Verify
                                            </Button>
                                            <div id="loader">
                                              <i className="ti-reload"></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="shape-1">
                        <img src={shape1} alt="" />
                      </div>
                      <div className="shape-2">
                        <img src={shape2} alt="" />
                      </div>
                      <div className="shape-3">
                        <img src={shape3} alt="" />
                      </div>
                      <div className="shape-4">
                        <img src={shape4} alt="" />
                      </div>
                    </div>
                    <div className="frame-shape-1">
                      <img src={frame1} alt="" />
                    </div>
                    <div className="frame-shape-2">
                      <img src={frame2} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default QrVerification;
