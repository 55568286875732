import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Hero from "../../components/hero/Hero";
import StorySection from "../../components/StorySection";
import PortfolioSection from "../../components/PortfolioSection";
import RsvpSection from "../../components/RsvpSection/RsvpSection";
import EventSection from "../../components/EventSection/EventSection";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";

const HomePage = () => {
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-2"} />
      <Hero />
      <StorySection />
      <PortfolioSection />
      <Footer footerClass={"wpo-site-footer-s2"} />
      <Scrollbar />
    </Fragment>
  );
};

export default HomePage;
