import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '../src/main-component/App/App';
import reportWebVitals from './reportWebVitals';
// import { ParallaxProvider } from 'react-scroll-parallax';
import './assets/css/font-awesome.min.css';
import './assets/css/themify-icons.css';
import './assets/css/animate.css';
import './assets/css/flaticon.css';
import './assets/sass/style.scss';

// import { PersistGate } from "redux-persist/integration/react";
// import { store, persistor } from "./store/index";
// import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <Provider store={store}>
    //     <PersistGate loading={null} persistor={persistor}>
    //         <ParallaxProvider>
                <App />
    //         </ParallaxProvider>
    //     </PersistGate>
    // </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
