import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "../HomePage";
import InvitationPage from "../InvitationPage";
import Rsvp from "../Rsvp";
import QrVerification from "../QR";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/welcome" element={<Homepage />} />
          <Route path="/invitation" element={<InvitationPage />} />
          <Route path="/rsvp" element={<Rsvp />} />
          <Route path="/qr" element={<QrVerification />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
