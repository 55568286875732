// data exchange type
const MEDIA_TYPE_JSON = "application/json";

const HEADERS = {
  Accept: MEDIA_TYPE_JSON,
  "Content-Type": MEDIA_TYPE_JSON,
};

//BACKEND APIs DETAILS
export const VERIFY_QR_CODE = {
  endpoint: "/api/qr/verify",
  method: "POST",
  headers: HEADERS,
};
