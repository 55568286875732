import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import bg from "../../assets/images/invitation/invitation-bg.jpg";
import cImg1 from "../../assets/images/slider/shape15.svg";
import cn1 from "../../assets/images/slider/corner1.svg";
import cn2 from "../../assets/images/slider/corner2.svg";
import cn3 from "../../assets/images/slider/corner3.svg";
import cn4 from "../../assets/images/slider/corner4.svg";

import shape1 from "../../assets/images/slider/shape13.svg";
import shape2 from "../../assets/images/slider/shape14.svg";

const InvitationPage = () => {
  document.title = `The Rey's Wedding Invitation | Be Our Guest`;
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-4"} />
      <section
        className="wpo-invitation-area-s2"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="hero-container">
          <div className="hero-inner">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="wpo-event-wrap">
                    <div className="wpo-event-item">
                      <div className="wpo-event-text">
                        <h2>Samantha & Emmanuel</h2>
                        <p>Our Big Day is April 13,2024</p>
                        <div className="inner-shape">
                          <img src={cImg1} alt="" />
                        </div>
                        <ul>
                          <li>
                            Saturday, 13 Apr. 2024 <br />
                            1:00 PM - 3:30 PM{" "}
                          </li>
                          <li>
                            Ekpoma, Edo State, Nigeria.
                          </li>
                          <li>+2347082085923 (Nora)</li>
                        </ul>
                        
                      </div>
                      <div className="shape-1">
                        <img src={cn1} alt="" />
                      </div>
                      <div className="shape-2">
                        <img src={cn2} alt="" />
                      </div>
                      <div className="shape-3">
                        <img src={cn3} alt="" />
                      </div>
                      <div className="shape-4">
                        <img src={cn4} alt="" />
                      </div>
                    </div>
                    <div className="frame-shape-1">
                      <img src={shape1} alt="" />
                    </div>
                    <div className="frame-shape-2">
                      <img src={shape2} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default InvitationPage;
