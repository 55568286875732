import { VERIFY_QR_CODE } from "./url_helper";

export const verifyQRCode = async (data) => {
    const { endpoint, method, headers } = VERIFY_QR_CODE;
    const apiResponse = await fetch(endpoint, {
      method: method,
      headers: headers,
      body: JSON.stringify(data),
    });
    const responseBody = await apiResponse.json();
    if (responseBody) {
      return responseBody;
    }
    return null;
  };