import React from "react";
import { Slide } from "react-awesome-reveal";
import SectionTitle from "../SectionTitle";
import sImg1 from "../../assets/images/event/rings.jpeg";
import sImg2 from "../../assets/images/event/reception.jpeg";
import sImg3 from "../../assets/images/event/afterparty.jpeg";
import LocationMap from "./Modal";

const Events = [
  {
    Simg: sImg1,
    title: "The Wedding",
    li1: "Saturday, 13 Apr. 2024",
    t1: "1:00 PM - 2:30 PM",
    li2: "Abuja, Nigeria",
    li3: "+234-809-271-0019",
    animation: "1200",
  },
  {
    Simg: sImg2,
    title: "The Reception",
    li1: "Monday, 12 Apr. 2023",
    t1: "2:30 PM - 5:30 PM",
    li2: "Abuja, Nigeria",
    li3: "+234-809-271-0019",
    animation: "1400",
  },
  {
    Simg: sImg3,
    title: "The After Party",
    li1: "Saturday, 13 Apr. 2024",
    t1: "7:00 PM - You are tired",
    li2: "Abuja, Nigeria",
    li3: "+234-809-271-0019",
    animation: "1600",
  },
];

const EventSection = (props) => {
  return (
    <section className="wpo-event-section section-padding" id="event">
      <div className="container">
        <SectionTitle subTitle={"Our Wedding"} MainTitle={"When & Where"} />
        <div className="wpo-event-wrap">
          <div className="row">
            {Events.map((event, eitem) => (
              <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                <Slide
                  direction="up"
                  duration={event.animation}
                  triggerOnce="true"
                >
                  <div className="wpo-event-item">
                    <div className="wpo-event-img">
                      <div className="wpo-event-img-inner">
                        <img src={event.Simg} alt="" />
                      </div>
                    </div>
                    <div className="wpo-event-text">
                      <div className="title">
                        <h2>{event.title}</h2>
                      </div>
                      <ul>
                        <li>{event.li1}</li>
                        <li>{event.t1}</li>
                        <li>{event.li2}</li>
                        <li>{event.li3}</li>
                        <li>
                          <LocationMap />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Slide>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventSection;
