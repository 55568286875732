import React from "react";
import { JackInTheBox } from "react-awesome-reveal";
import SectionTitle from "../SectionTitle";
import sImg1 from "../../assets/images/story/4.jpg";
import sImg2 from "../../assets/images/story/5.jpg";
import sImg3 from "../../assets/images/story/6.jpg";

import flowerShape from "../../assets/images/story/flower.svg";
import flowerShape2 from "../../assets/images/story/flower-2.svg";
import pin from "../../assets/images/story/pin.svg";
import ring from "../../assets/images/story/ring.svg";

import shape1 from "../../assets/images/story/flower-shape1.svg";
import shape2 from "../../assets/images/story/flower-shape2.svg";
import shape3 from "../../assets/images/story/flower-shape3.svg";
import shape4 from "../../assets/images/story/flower-shape4.svg";

import howWeMet from "../../assets/images/bridegroom/hwfm.jpeg";
import howWeStartedDating from "../../assets/images/bridegroom/hwsd.jpeg";
import hePropSheSaidYes from "../../assets/images/bridegroom/hpssy.jpeg";

const Storys = [
  {
    sImg: howWeMet,
    flower: flowerShape,
    title: "How we meet",
    date: "March 2022",
    description: `On a sunny afternoon, I was at the cricket pitch trying to catch a game. Within minutes, my attention was drawn to a passionate cricketer who exuded boundless energy while cheering for her team. Her infectious enthusiasm, 
    radiant smile, and unwavering passion compelled me to strike up a conversation. We immediately bonded over our mutual love for the sport, sharing stories about our favourite players and memorable matches. 
    We shared contact information and what began as a connection at the cricket pitch swiftly evolved into a blossoming romance, proving that love can unexpectedly flourish in even the most unconventional places, like a cricket pitch.`,
  },
  {
    sImg: howWeStartedDating,
    flower: flowerShape2,
    title: "How we started dating",
    date: "July 2022",
    description: `After months of bonding, our connection had grown stronger. Our conversations had shifted from cricket to personal matters, and our support for each other deepened. On her birthday in 2022, I visited Abuja where she resided.
    I asked her out on a dinner date, and she accepted. She showed up looking stunning in a classic black dinner dress. The dress flattered her figure with a tailored fit, and its simple elegance made her stand out. 
    Her outfit was not only a fashion statement but also a symbol of her poise and confidence. She carried herself with grace, making the black dress a reflection of her timeless style and sophistication. 
    During the dinner, Amidst the restaurant's gentle ambiance, our unspoken feelings emerged. I took her hand, and she didn't pull away. At that moment, I knew I wanted more than friendship and I shared my intentions with her and with a radiant smile, 
    she said "I feel the same way". and like they say, the rest is history.`,
    order1: "order-lg-2 order-1",
    order2: "order-lg-1 order-2",
  },
  {
    sImg: hePropSheSaidYes,
    flower: flowerShape,
    title: "He proposed, She said yes",
    date: "August 2023",
    description: `Her deep affection for the ocean is evident, to the extent that the initials of her name spell out the word "SEA." She frequently expressed her desire for a special proposal, consistently emphasizing that it should take place by the sea.

    I organized a trip to Lagos with the intention of proposing to her. We decided to visit the beach on a Friday, and unknown to her, I had meticulously planned the entire proposal. I had obtained a ring, and after weeks of preparation, everything was set.
    
    As anticipated, she suggested going on a boat ride. Delightedly, I agreed, leading her onto the boat. As we sailed into the open water, my heart raced with nervous excitement, an unparalleled sensation in my life.
    
    With tearful eyes, I gazed into hers, expressing the depth of my feelings and my desire to spend the rest of my life with her. Falling to one knee, I presented a small box containing a stunning white gold ring that glittered in the sunlight. 
    I asked her to marry me, and her joyous scream of "YES!" resonated as tears of happiness streamed down her face. I carefully slid the ring onto her finger, creating a moment that would be etched in our memories forever.`,
  },
];

const StorySection = (props) => {
  return (
    <section className="wpo-story-section-s2 section-padding" id="story">
      <div className="container">
        <SectionTitle subTitle={"Our Story"} MainTitle={"How it Happened"} />
        <div className="wpo-story-wrap">
          {Storys.map((story, st) => (
            <div className="wpo-story-item" key={st}>
              <div className="row">
                <div className={`col col-lg-6 col-12 ${story.order1}`}>
                  <div className="wpo-story-img">
                    <img src={story.sImg} alt="" />
                  </div>
                </div>
                <div className={`col col-lg-6 col-12 ${story.order2}`}>
                  <JackInTheBox duration="1000" triggerOnce="true">
                    <div className="wpo-story-content">
                      <div className="thumb">
                        <span>{story.date}</span>
                        <div className="pin">
                          <img src={pin} alt="" />
                        </div>
                      </div>
                      <h2>{story.title}</h2>
                      <p>{story.description}</p>
                      <div className="flower">
                        <img src={story.flower} alt="" />
                      </div>
                    </div>
                  </JackInTheBox>
                </div>
              </div>
              <div className="ring-wrap">
                {Array.apply(null, { length: 10 }).map((e, i) => (
                  <div className="ring" key={i}>
                    <img src={ring} alt="" />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flower-shape-1">
        <img src={shape1} alt="" />
      </div>
      <div className="flower-shape-2">
        <img src={shape2} alt="" />
      </div>
      <div className="flower-shape-3">
        <img src={shape3} alt="" />
      </div>
      <div className="flower-shape-4">
        <img src={shape4} alt="" />
      </div>
    </section>
  );
};

export default StorySection;
